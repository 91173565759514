import Vue from 'vue';
import Vuex from 'vuex';
import storage from 'store';
import app from './modules/app';
import user from './modules/user';
import { ACCESS_TOKEN } from '@/constants/storage';
import { loginApi, menuApi, customerApi } from '@/api';
import getters from './getters';
import { Message } from 'element-ui';
import actions from '@/micro/actions';
function _toUpperCase(a: string) {
  return a[0].toUpperCase() + a.substr(1);
}
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAddRouters: false,
    userInfo: {},
    addRouters: [],
    permissions: [],
    partnerList: [] as any[], // (客户后台)
    partnerId: '', // (客户后台)
    isPartnerSelectAble: true, // 客户id是否能切换(客户后台)
    supplier: {},
    saleSupplier: {},
    vouerSupplier: {},
    stockSupplier: {},
    hotelSupplier: {},
    supplierList: [],
    saleSupplierList: [],
    vouerSupplierList: [],
    stockSupplierList: [],
    hotelSupplierList: [],
  },
  getters,
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setPermissions(state, payload) {
      state.permissions = payload;
    },
    setRouters(state, payload) {
      state.addRouters = payload;
    },
    setAddRouters(state, payload) {
      state.isAddRouters = payload;
    },
    setPartnerId(state, payload) {
      //  (客户后台)
      state.partnerId = payload;
      localStorage.setItem('customer_partnerId', payload);
    },
    setPartnerList(state, payload) {
      //  (客户后台)
      state.partnerList = payload;
    },
    setSupplierList(state: any, { supplierList, type }) {
      state[type + 'List'] = supplierList;
    },
    setSupplier: (state: any, supplier: any) => {
      state.supplier = supplier.data;
    },
    setHotelSupplier: (state: any, supplier: any) => {
      state.hotelSupplier = supplier.data;
    },
    setSaleSupplier: (state: any, supplier: any) => {
      state.saleSupplier = supplier.data;
    },
    setVouerSupplier: (state: any, supplier: any) => {
      state.vouerSupplier = supplier.data;
    },
    setStockSupplier: (state: any, supplier: any) => {
      state.stockSupplier = supplier.data;
    },
  },
  actions: {
    // 登录之后获取用户信息
    getUserInfo({ commit, dispatch }) {
      return menuApi.getUserBaseInfo().then(res => {
        if (res.success) {
          commit('setUserInfo', res.data?.userInfo);
          localStorage.setItem('user_info', JSON.stringify(res.data?.userInfo));
          dispatch('fetchPartnerList');
        }
        return res;
      });
    },
    // 登录
    login({ dispatch }, params) {
      return loginApi.login(params).then(res => {
        if (res?.data?.accessToken) {
          storage.set(ACCESS_TOKEN, res.data.accessToken);
          actions.setGlobalState({
            type: 'token',
            payload: res.data.accessToken,
          });
          return res;
        }
        return Promise.reject();
      });
    },
    // 登出
    logout({ commit }, payload) {
      if (payload) {
        Message.warning(payload);
      }
      window.history.pushState(null, '', '/login');

      // setTimeout(() => {
      localStorage.setItem('user_info', '');
      localStorage.setItem('customer_partnerId', '');
      commit('setRouters', []);
      commit('setAddRouters', false);
      // }, 200);
    },
    async fetchPartnerList({ commit }) {
      // (客户后台)
      if (JSON.stringify(this.state.userInfo) === '{}') {
        //未登录不请求
        return;
      }
      const res = await customerApi.getCustomerList({
        currentPage: 1,
        pageSize: 10000,
        customerStatus: 'NORMAL',
      });
      let customerId: string | null = '';
      const userInfo = localStorage.getItem('user_info');
      if (userInfo && JSON.parse(userInfo).userType === '1') {
        customerId = JSON.parse(userInfo).companyId;
        this.state.isPartnerSelectAble = false;
      } else {
        
        customerId = localStorage.getItem('customer_partnerId');
        this.state.isPartnerSelectAble = true;
      }
      const findItem = res?.data?.records?.filter(
        (item: any) => customerId === item.id,
      );
      if (customerId && findItem) {
        commit('setPartnerId', customerId);
      } else {
        if (res.data?.records && res.data?.records[0]) {
          commit('setPartnerId', res.data?.records[0].id);
          localStorage.setItem(
            'customer_partnerId',
            (res as any).data?.records[0].id,
          );
        }
      }
      commit('setPartnerList', (res as any).data?.records);
    },
  },
  modules: {
    app,
    user,
  },
});
