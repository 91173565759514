import { setRequest, services } from '@swagger-api/api-usercenter-server';
import axios from '@/utils/request';
import qs from 'qs';

const request: any = {
  get: (params: any) => {
    let query = params.queryParams;
    const form = params.queryParams?.form || {};
    if (typeof form === 'object') {
      query = form;
    }
    return axios.get(`${params.url}?${qs.stringify(query)}`);
  },
  post: (params: any) => axios.post(params.url, params.requestBody),
  put: (params: any) => axios.put(params.url, params.requestBody),
  delete: (params: any) => axios.delete(params.url, params.requestBody),
  options: (params: any) => axios.options(params.url, params.requestBody),
  patch: (params: any) => axios.patch(params.url, params.requestBody),
  head: (params: any) => axios.head(params.url, params.requestBody),
};

setRequest(request as any);
export const customerApi = {
  getPartnerList: services.postCustomerInfoFindPage,
  listByScene: services.getSupplierListBySceneScene,
  getSupplierList: services.postSupplierPage,
  // 顶部获取客户列表
  getCustomerList(data: any) {
    const params = {
      url: `/usercenter/customerInfo/findPageByUserDataScope`,
      requestBody: data,
    };
    return request.post(params);
  },
};
