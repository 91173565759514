import { mapActions } from 'vuex';
const { body } = document;
const MEDIUM_WIDTH = 992;
const SIDEBAR_DIFF = 30;
const NO_SIDEBAR_DIFF = 192;

export default {
  watch: {
    $route() {
      if (this.device === 'mobile' && this.sidebar.opened) {
        this.closeSideBar({ withoutAnimation: false });
      }
    },
  },
  beforeMount() {
    window.addEventListener('resize', this.resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  mounted() {
    const isMobile = this.isMobile();
    if (isMobile) {
      this.toggleDevice('mobile');
      this.closeSideBar({ withoutAnimation: true });
    }
  },
  methods: {
    ...mapActions('app', ['toggleDevice', 'closeSideBar', 'openSideBar']),
    isMobile() {
      const rect = body.getBoundingClientRect();
      if (this.sidebar.opened) {
        return rect.width + SIDEBAR_DIFF < MEDIUM_WIDTH;
      }
      return rect.width + NO_SIDEBAR_DIFF < MEDIUM_WIDTH;
    },
    resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.isMobile();
        this.toggleDevice(isMobile ? 'mobile' : 'desktop');
        if (isMobile) {
          this.closeSideBar({ withoutAnimation: true });
        }
        if (window.innerWidth >= MEDIUM_WIDTH) {
          this.openSideBar({ withoutAnimation: true });
        }
      }
    },
  },
};
