import {services, setRequest,} from '@swagger-api/api-usercenter-server';
import request from '@/utils/request';
import qs from 'qs';
setRequest({
  get: params => {
    let query = params.queryParams;
    const form = params.queryParams?.form || {};
    if (typeof form === 'object') {
      query = form;
    }
    request.get(`${params.url}?${qs.stringify(query)}`);
  },
  post: params => request.post(params.url, params.requestBody),
  put: params => request.put(params.url, params.requestBody),
  delete: params => request.delete(params.url),
});

export const supplierApi = {
  getSupplierList: services.postSupplierPage,
};
